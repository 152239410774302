import React from "react"
import "./directory-block.scss"

const DirectoryBlock = ({
  backgroundColor,
  heading,
  directoryEntries,
}) => {
  const person = directoryEntries.some(entry => entry.directoryType === "person")
  const rows = person ? "3" : "2"

  return <div className={`TableBlock bg--${backgroundColor}`}>
    <div className={"TableBlock-wrapper"}>
      {heading && <h2 className={"TableBlock-heading Directory-heading"}>{heading}</h2>}

      <div className={"TableBlock-table"}>
        <div className={`TableBlock-tablehead cols-${rows}`}>
          <h4>Name</h4>
          <h4>EXT</h4>
          {person && <h4>Email</h4>}
        </div>

        <div className={`TableBlock-tablebody cols-${rows}`}>
          {directoryEntries.map(entry => {
            return <div className={`TableRow cols-${rows}`} id={entry.id}>
              <div className={"TableRow-content"}>
                <div>
                  <h4>{entry.title}</h4>
                  <p>{entry.heading}</p>
                </div>
              </div>
              <p className={"TableRow-label"}>{entry.plainText}</p>
              <p className={"TableRow-copy"}>{entry.email}</p>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>
}

export default DirectoryBlock
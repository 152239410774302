import React from "react";

//content blocks
import landingHeader from "./landing-header";
import detailedNavigation from "./detailed-navigation";
import alertReadMore from "./alert-read-more";
import pageHeader from "./page-header";
import overviewPlatform from "./overview-platform";
import youtubeModal from "./youtube-modal";
import resourcesFaq from "./resources-faq";
import tableBlock from "./table-block";
import tableRow from "./table-row";
import twoColumnBlock from "./two-column-block";
import copyBlock from "./copy-block";
import embedBlock from "./embed-block"

const components = {
  landingHeader,
  alertReadMore,
  detailedNavigation,
  pageHeader,
  overviewPlatform,
  youtubeModal,
  resourcesFaq,
  tableBlock,
  tableRow,
  twoColumnBlock,
  copyBlock,
  embedBlock
};

const Blocks = ({ blocks, entry }) => {
  return blocks?.map((block) => {
    const Comp = components[block.typeHandle];
    if (!Comp) {
      console.warn(`Missing block type ${block.__typename}`);
      return null;
    }
    return <Comp key={block.id} {...block} entry={entry} />;
  });
};
export default Blocks;

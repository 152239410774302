import React from "react"
import "./directory.scss"
import { useQuery } from "@apollo/react-hooks"
import query from "./query";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";
import PageHeader from "blocks/page-header";
import DirectoryBlock from "blocks/directory-block";


const Directory = () => {
  const { loading, error, data } = useQuery(query);

  if (loading) return <Loading />;
  if (error) return <Error />;

  if (!data.entry) {
    return <NotFound />;
  }

  const { heading, plainText, directoryBlock } = data.entry;
  return <div>
    <PageHeader heading={heading} plainText={plainText} />
    <div className="padding-spacer"></div>
    {directoryBlock.map(block => {
      return <div key={block.id}>
        <DirectoryBlock {...block} />
      </div>
    })}
  </div>
}

export default Directory
import { gql } from "apollo-boost";

export default gql`
  query {
    entry(section: "directoryPage") {
      ... on directoryPage_directoryPage_Entry {
        id
        heading
        plainText
        directoryBlock {
          ... on directoryBlock_directorySection_BlockType {
            id
            typeHandle
            backgroundColor
            heading
            directoryEntries {
              ... on directoryEntry_default_Entry {
                id
                email
                directoryType
                title
                superheading
                heading
              }
            }
          }
        }
      }
    }
  }
`
import { useQuery } from "@apollo/react-hooks";
import { idtype } from "querypieces"
import pageBuilder from "blocks/query";
import { gql } from "apollo-boost";

const query = gql`
query($param:String, $offset: Int, $limit: Int) {
  entries(section: ["pages","blog","directoryEntry"], search: $param, orderBy: "score",offset: $offset, limit: $limit) {
    ${idtype}
    title
    url
    uri
    __typename

    ...on pages_page_Entry {
      ${pageBuilder}
    }

    ...on blog_default_Entry {
      heading
      superheading
      label
      plainText
    }

    ...on directoryEntry_default_Entry {
      heading
      email
      directoryType
      title
      superheading
      heading
    }
  }

  entryCount(section: ["pages","blog", "directoryEntry"], search: $param)
}
`

const useSearch = (param) => {
  return useQuery(query, {
    variables: {
      param,
    },
    notifyOnNetworkStatusChange: true,
  });
};

export default useSearch;

import { useState, useEffect, useCallback } from "react";

// const getFirstHtmlElement = (htmlString) => {
//   const tempDiv = document.createElement('div');
//   tempDiv.innerHTML = htmlString.trim();
//   const firstElement = tempDiv.firstElementChild;
//   return firstElement || null;
// }

const useSearchExcerpt = (searchTerm, entry) => {
  const [snippet, setSnippet] = useState({});

  const updateSnippet = useCallback((params) => {
    setSnippet(params)
    return snippet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (entry.__typename === "pages_page_Entry") {

      return entry.pageBuilder.forEach((block) => {
        if (block.children) {
          return block.children.forEach((child) => {

            if (child.heading && child.heading.toLowerCase().includes(searchTerm)) {
              updateSnippet({ content: child.heading, id: child.id })
            } else if (child.richText && child.richText.toLowerCase().includes(searchTerm)) {
              if (child.typeHandle === "tableRow") {
                updateSnippet({ content: child, id: child.id })
              } else {
                updateSnippet({ content: child.richText, id: child.id })
              }
            } else if (child.plainText && child.plainText.toLowerCase().includes(searchTerm)) {
              updateSnippet({ content: child.plainText, id: child.id })
            }

            return snippet
          })
        }

        if (block.heading && block.heading.toLowerCase().includes(searchTerm)) {
          updateSnippet({ content: block.heading, id: block.id })
          return snippet
        } else if (block.plainText && block.plainText.toLowerCase().includes(searchTerm)) {
          updateSnippet({ content: block.plainText, id: block.id })
          return snippet
        } else if (block.remainingText && block.remainingText.toLowerCase().includes(searchTerm)) {
          updateSnippet({ content: block.remainingText, id: block.id })
          return snippet
        } else if (block.richText && block.richText.toLowerCase().includes(searchTerm)) {
          updateSnippet({ content: block.richText, id: block.id })
          return snippet
        }

        return snippet
      })
    } else if (entry.__typename === "blog_default_Entry") {
      const blogContent = `${entry.heading} | ${entry.superheading}<br />${entry.plainText}`
      updateSnippet({ content: blogContent, id: entry.id })
    } else if (entry.__typename === "directoryEntry_default_Entry") {
      updateSnippet({ content: entry.email, id: entry.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, searchTerm])

  return snippet
}

export default useSearchExcerpt;

